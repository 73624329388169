/////////////////////////////////////////////////////////////////////////////////////
//
// Projekt Tierwohl-KI
// COPYRIGHT (c)2023 by novutrack GmbH
//
// Navbar.js
// Hauptdatei der Anwendung
//
// Author:          Markus Seidl
// Erstellt am:     29.02.2024
// Letzte Änderung: 29.02.2024
// von:             Markus Seidl
//
///////////////////////////////////////////////////////////////////////////////////

import React, { useContext, useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';

import { Container, Row, Col } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { AuthContext } from "../context/authContext";
import axios from "axios";

function NavScrollExample() {
  const { currentUser } = useContext(AuthContext);
  const [dateTime, setDateTime] = useState(new Date());

  const operationID = currentUser.betriebID;
  // console.log(userData);
  const [operationData, setOperationData]  = useState([]);
  // console.log(operationData);
  useEffect(() => {
    const fetchtableData = async () => {
      try {

        const res = await axios.get("/api/operation/", {
          params: { operationID, bust: Date.now() },
        });
        setOperationData(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchtableData();
  }, []);




  useEffect(() => {
    const timer = setInterval(() => {
      setDateTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString();
  };


  return (
    <Navbar expand="lg" 
      // className="bg-body-tertiary"
      className="bg-white shadow"
    >
      <Container fluid style={{ height: "70px" }}>
      {/* <Col xs={7}>{<h1>Hallo, 
      
      <p>{currentUser?.vorname}</p>
      </h1>}</Col> */}

    {/* <Col xs={12} md={5}>
      <h1>Betrieb: <span>{operationData[0]?.bezeichnung}</span></h1>
    </Col> */}
    {/* <Row> */}
      <Col xs={6} sm={6} md={5} style={{ whiteSpace: 'nowrap' }}>
        <div><span>Betrieb:</span></div>
        <div><span>{operationData[0]?.bezeichnung}</span></div>
      </Col>

    {/* <Col xs={6} md={2}>
      <div>Datum: <span>{formatDate(dateTime)}</span></div>
    </Col>
    <Col xs={6} md={3}>
      <div>Uhrzeit: <span>{formatTime(dateTime)}</span></div>
    </Col> */}

      <Col xs={6} sm={5} md={5} style={{ whiteSpace: 'nowrap' }}>
        <div>Datum: <span>{formatDate(dateTime)}</span></div>
        <div>Uhrzeit: <span>{formatTime(dateTime)}</span></div>
      </Col>
    {/* </Row> */}
   
        {/* <Navbar.Brand href="#">Navbar scroll</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" /> */}
        {/* <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link href="#action1">Home</Nav.Link>
            <Nav.Link href="#action2">Link</Nav.Link>
            <NavDropdown title="Link" id="navbarScrollingDropdown">
              <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action4">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action5">
                Something else here
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="#" disabled>
              Link
            </Nav.Link>
          </Nav>
          <Form className="d-flex">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            />
            <Button variant="outline-success">Search</Button>
          </Form>
        </Navbar.Collapse> */}
      </Container>
    </Navbar>
  );
}

export default NavScrollExample;