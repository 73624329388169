/////////////////////////////////////////////////////////////////////////////////////
//
// Projekt Tierwohl-KI
// COPYRIGHT (c)2023 by novutrack GmbH
//
// Profile.js
// Profil Page der Anwendung
//
// Author:          Markus Seidl
// Erstellt am:     29.02.2024
// Letzte Änderung: 29.02.2024
// von:             Markus Seidl
//
///////////////////////////////////////////////////////////////////////////////////

import React from "react";

import Table from "react-bootstrap/Table";
import {
  Container,
  Row,
  Col,
  Modal,
  Form,
  Card,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../css/main.scss";
import { Store } from "react-notifications-component";
// import { Store } from "react-notifications-component";

const Operation = () => {
  //   const [currentUser, setCurrentUser]  = useState([]);
  //   if (currentUser.length == 0) {
  //     if (currentUser.id == null) {
  // currentUser = JSON.parse(localStorage.getItem("user"));
  //   setCurrentUser(JSON.parse(localStorage.getItem("user")));

  // console.log("!currentUser ",currentUser);
  //     }
  //   }
  const currentUser = JSON.parse(localStorage.getItem("user"));

  // const userID = currentUser.id;

  // const [userData, setUserData] = useState([]);
  const [operationData, setOperationData] = useState([]);
  const [countries, setCountries] = useState([]);
  // const [isDataFetched, setIsDataFetched] = useState(false);

  //   useEffect(() => {
  //     const fetchtableData = async () => {
  //       // if (!isDataFetched && userID) {
  //         try {
  //           const res = await axios.get("/api/profile/", {
  //             params: { userID, bust: Date.now() },
  //           });

  //           setUserData(res.data);
  //           // setIsDataFetched(true);
  //         } catch (err) {
  //           console.log(err);
  //         }
  //       // }
  //     };
  //     fetchtableData();
  //   }, []);
  const operationID = currentUser.betriebID;
  // console.log(userData);

  //   useEffect(() => {
  //     const fetchtableData = async () => {
  //       try {

  //         const res = await axios.get("/api/operation/", {
  //           params: { operationID, bust: Date.now() },
  //         });
  //         setOperationData(res.data);
  //       } catch (err) {
  //         console.log(err);
  //       }
  //     };
  //     fetchtableData();
  //   }, []);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const res = await axios.get("/api/country");
        setCountries(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchtOperationData = async () => {
      try {
        let operationID;
        if (currentUser != null) {
          operationID = currentUser.betriebID;

          const res = await axios.get("/api/operation/", {
            params: { operationID, bust: Date.now() },
          });
          // console.log(res.data);
          setOperationData(res.data[0]);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchtOperationData();
  }, []);

  const [inputs, setInputs] = useState({
    id: operationData.id,
    bezeichnung: operationData.bezeichnung,
    strasse: operationData.strasse,
    plz: operationData.plz,
    ort: operationData.ort,
    landID: operationData.landID,
    land: operationData.land,
    betriebsnr: operationData.betriebsnr,
  });

  useEffect(() => {
    setInputs({
      id: operationData.id,
      bezeichnung: operationData.bezeichnung,
      strasse: operationData.strasse,
      plz: operationData.plz,
      ort: operationData.ort,
      landID: operationData.landID,
      land: operationData.land,
      betriebsnr: operationData.betriebsnr,
    });
  }, [operationData]);
//   console.log("inputs", inputs);

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleClickSave = async (e) => {
    e.preventDefault();
    // console.log("inputs", inputs);

  try {

    const form = document.querySelector(".needs-validation");
    form.classList.add("was-validated");

    const areAllFieldsFilled = (inputs.id !== "" && inputs.id !== null) &&
    (inputs.bezeichnung !== "" && inputs.bezeichnung !== null) &&
    (inputs.strasse !== "" && inputs.strasse !== null) &&
    (inputs.plz !== "" && inputs.plz !== null) &&
    (inputs.ort !== "" && inputs.ort !== null) &&
    (inputs.landID !== "" && inputs.landID !== null) &&
    (inputs.betriebsnr !== "" && inputs.betriebsnr !== null);
  
  


    if (!areAllFieldsFilled) {
    // Mindestens ein Feld ist nicht befüllt, zeige eine Fehlermeldung an
    // console.log("Füllen Sie bitte die erforderlichen Daten aus.");
    alert("Füllen Sie bitte die erforderlichen Daten aus.");
    return
    }

    // console.log(inputs);
   
      // console.log("save Betrieb",inputs);
      await axios.put("/api/operation", inputs);
      Store.addNotification({
        title: "Erfolgreich",
        message: "Die Betriebs-Daten wurde erfolgreich aktualisiert!",
        // type: "default",
        // type: "info",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 6000,
          onScreen: true,
        },
      });

    } catch (err) {
      console.error("Fehler beim Speichern der Stallung: ", err);
      // Hier könntest du eine Benachrichtigung für den Fehler anzeigen
      Store.addNotification({
          title: "Fehler",
          message: "Beim Hinzufügen der Betriebs-Daten ist ein Fehler aufgetreten.",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
          duration: 6000,
          onScreen: true
          }
      });
    }
  };

  return (
    // <Container fluid>
    <Container fluid>
      <div>
        <div className="main">
          <Row>
            <Col>
              <div className="contentbox">
                <h1 className="title">Administration Betrieb</h1>
                <Row className="mb-3">
                  <Col>
                    <Card className="shadow mb-3">
                      <Card.Header className="py-3">
                        <p className="m-0 fw-bold">Betriebs-Daten</p>
                      </Card.Header>
                      <Card.Body>
                        <form className="needs-validation">
                          {/* <Row className="pb-2">
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  disabled
                                  type="text"
                                  name="id"
                                  className="form-control "
                                  id="floatingInput"
                                  defaultValue={operationData.id}
                                  onChange={handleChange}
                                />
                                <label>ID</label>
                              </div>
                            </Col>
                          </Row> */}
                          <Row className="pb-2">
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  // disabled={activetrip.statusID === 6 }
                                  required
                                  type="text"
                                  name="betriebsnr"
                                  className="form-control "
                                  id="floatingInput"
                                  defaultValue={operationData.betriebsnr}
                                  onChange={handleChange}
                                />
                                <label>Betriebsnr</label>
                              </div>
                            </Col>
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  // disabled={activetrip.statusID === 6 }
                                  required
                                  type="text"
                                  name="bezeichnung"
                                  className="form-control"
                                  id="floatingInput"
                                  defaultValue={operationData.bezeichnung}
                                  onChange={handleChange}
                                />
                                <label htmlFor="floatingInput">
                                  Bezeichnung
                                </label>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  // disabled
                                  required
                                  type="text"
                                  name="strasse"
                                  className="form-control"
                                  id="floatingInput"
                                  defaultValue={operationData.strasse}
                                  onChange={handleChange}
                                />
                                <label htmlFor="floatingInput">Adresse</label>
                              </div>
                            </Col>
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  // disabled={activetrip.statusID === 6 }
                                  required
                                  type="text"
                                  name="plz"
                                  className="form-control"
                                  id="floatingInput"
                                  defaultValue={operationData.plz}
                                  onChange={handleChange}
                                />
                                <label htmlFor="floatingInput">Plz</label>
                              </div>
                            </Col>
                          </Row>

                          <Row className="pb-2">
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  // disabled={activetrip.statusID === 6 }
                                  required
                                  type="text"
                                  name="ort"
                                  className="form-control "
                                  id="floatingInput"
                                  defaultValue={operationData.ort}
                                  onChange={handleChange}
                                />
                                <label>Ort</label>
                              </div>
                            </Col>

                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <select
                                  className="form-control"
                                  defaultValue={inputs.land}
                                  onChange={handleChange}
                                  name="landID"
                                  // required
                                >
                                  {operationData.land ? (
                                    <option hidden value="">
                                      {operationData.land}
                                    </option>
                                  ) : (
                                    <option hidden value="">
                                      -- Bitte ausw&auml;hlen --
                                    </option>
                                  )}

                                  {countries.map((country) => {
                                    return (
                                      <option
                                        key={country.ID}
                                        value={country.ID}
                                      >
                                        {country.Bezeichnung}
                                        {/* {console.log("country.landID: ", country.ID)} */}
                                      </option>
                                    );
                                  })}
                                </select>
                                <label htmlFor="floatingSelect">Land</label>
                              </div>
                            </Col>
                          </Row>

                          <Button
                            onClick={handleClickSave}
                            className="submitbutton"
                          >
                            &Auml;nderungen speichern
                          </Button>
                        </form>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default Operation;
