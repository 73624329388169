/////////////////////////////////////////////////////////////////////////////////////
//
// Projekt ZARAPP
// COPYRIGHT (c)2023 by novutrack GmbH
//
// Login.js
// Hauptdatei der Anwendung
//
// Author:          Julia Cossee
// Erstellt am:     09.03.2023
// Letzte Änderung: 09.03.2023
// von:             Julia Cossee
//
///////////////////////////////////////////////////////////////////////////////////

import axios from "axios";
import { useContext } from "react";
import React, { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";

import Logo from "../images/logo_CW.svg";

import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "bootstrap";

import "../css/login.scss";

// TODO: Bildschirm zentrieren
// TODO: Fenstergröße anpassen
// TODO: Login Form nach unten
// TODO: Überprüfung Responsive Design

const Login = () => {
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });
  const [err, setError] = useState(null);

  const navigate = useNavigate();

  const { login, currentUser } = useContext(AuthContext);

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(inputs);
      navigate("/");
    } catch (err) {
      setError(err.response.data);
    }
  };
  return (
    <div id="logincontainer">
      <Container fluid>
        <Row>
          <Col>
            <img className="loginlogo" src={Logo}></img>
          </Col>
        </Row>
        <Row>
          <Col>
            <h1>Willkommen beim ChickenWatcher</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form onSubmit={handleSubmit}>
              <h5>Anmeldung</h5>
              <input
                required
                type="text"
                className="form-control"
                placeholder="Benutzername"
                name="username"
                onChange={handleChange}
              />

              {/* <input
                required
                type="text"
                className="form-control"
                placeholder="Email"
                name="email"
                onChange={handleChange}
              /> */}

              <input
              required
                className="form-control"
                
                type="password"
                placeholder="Passwort"
                name="password"
                onChange={handleChange}
              />

              <Button  type="submit" variant="primary">
                Anmelden
              </Button>
            </Form>
          </Col>
        </Row>
        <Row></Row>
      </Container>
    </div>

    // <div className="main">
    //   <div className="welcome">
    //     <img src={Logo}></img>
    //     <h1>Willkommen bei der Rinderzucht Austria</h1>
    //   </div>
    //   <form>
    //     <h1>Anmeldung</h1>
    // <input
    //   required
    //   type="text"
    //   placeholder="Email"
    //   name="email"
    //   onChange={handleChange}
    // />
    // <input
    //   required
    //   type="password"
    //   placeholder="Passwort"
    //   name="password"
    //   onChange={handleChange}
    // />
    //     <button onClick={handleSubmit}>Anmelden</button>
    //     {err && <p>{err}</p>}
    //   </form>
    // </div>
  );
};

export default Login;
