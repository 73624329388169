/////////////////////////////////////////////////////////////////////////////////////
//
// Projekt Tierwohl-KI
// COPYRIGHT (c)2023 by novutrack GmbH
//
// NewFlock.js
// NeueHerde Page der Anwendung
//
// Author:          Markus Seidl
// Erstellt am:     29.02.2024
// Letzte Änderung: 29.02.2024
// von:             Markus Seidl
//
///////////////////////////////////////////////////////////////////////////////////

import React from "react";

import Table from "react-bootstrap/Table";
import {
  Container,
  Row,
  Col,
  Modal,
  Form,
  Card,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Store } from "react-notifications-component";

const NewFlock = () => {

    // const [folk, setFolk] = useState([]);
    // useEffect(() => {
    //     const fetchFolk = async () => {
    //         try {
    //             const res = await axios.get("/api/folk");
    //         setFolk(res.data);
    //         } catch (err) {
    //         console.log(err);
    //         }
    //     };
    //     fetchFolk();
    // }, []);

    // const [usage, setUsage] = useState([]);
    // useEffect(() => {
    //     const fetchUsage = async () => {
    //         try {
    //             const res = await axios.get("/api/usage");
    //         console.log(res.data);
    //         setUsage(res.data);
    //         } catch (err) {
    //         console.log(err);
    //         }
    //     };
    //     fetchUsage();
    // }, []);

    const currentUser = JSON.parse(localStorage.getItem("user"));
    // console.log(currentUser);
  
    const [stables, setStables] = useState([]);
    useEffect(() => {
      const fetchStable = async () => {
        try {
          const operationID = currentUser.betriebID;
          // const res = await axios.get("/api/stable/", operationID);
          const res = await axios.get("/api/stable/", {
            params: { operationID },
          });
          setStables(res.data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchStable();
    }, []);

    const [breeds, setBreeds] = useState([]);
    useEffect(() => {
        const fetchBreeds = async () => {
            try {
                const res = await axios.get("/api/breeds");
                // console.log(res.data);
            setBreeds(res.data);
            } catch (err) {
            console.log(err);
            }
        };
        fetchBreeds();
    }, []);

    const [selectedBreedId, setSelectedBreedId] = useState(null); 
    const handleBreedChange = (e) => {
        const selectedId = e.target.value;
        setSelectedBreedId(selectedId);
        setInputs((prev) => ({ ...prev, rasseID: selectedId }));
    };
    const selectedBreedUsage = breeds.find(breed => breed.id.toString() === selectedBreedId)?.verwendungbezeichnung;


    const [inputs, setInputs] = useState({
        betriebID: currentUser.betriebID,
        // id: operationData.id,
        // bezeichnung: "operationData.bezeichnung",
        // strasse: operationData.strasse,
        // plz: operationData.plz,
        // ort: operationData.ort,
        // landID: operationData.landID,
        // land: operationData.land,
        // betriebsnr: operationData.betriebsnr,
      });

    const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleClickSave = async (e) => {
        e.preventDefault();
        // console.log("inputs", inputs);
    
      try {
    
        const form = document.querySelector(".needs-validation");
        form.classList.add("was-validated");
    
        const areAllFieldsFilled = 
        (inputs.ausstallung !== "" && inputs.ausstallung !== null) &&
        (inputs.bezeichnung !== "" && inputs.bezeichung !== null) &&
        (inputs.einstallung !== "" && inputs.einstallung !== null) &&
        (inputs.rasseID !== "" && inputs.rasseID !== null);
      
      
    
    
        if (!areAllFieldsFilled) {
        // Mindestens ein Feld ist nicht befüllt, zeige eine Fehlermeldung an
        console.log("Füllen Sie bitte die erforderlichen Daten aus.");
        alert("Füllen Sie bitte die erforderlichen Daten aus.");
        return
        }
    
        // console.log(inputs);
       
          // console.log("save Herde",inputs);
          await axios.post("/api/folk", inputs);
          Store.addNotification({
            title: "Erfolgreich",
            message: "Die Herden-Daten wurde erfolgreich gespeichert!",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 6000,
              onScreen: true,
            },
          });
    
        } catch (err) {
          console.error("Fehler beim Speichern der Herden-Daten: ", err);
          // Hier könntest du eine Benachrichtigung für den Fehler anzeigen
          Store.addNotification({
              title: "Fehler",
              message: "Beim Hinzufügen der Betriebs-Daten ist ein Fehler aufgetreten.",
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
              duration: 6000,
              onScreen: true
              }
          });
        }
      };

  return (
    <Container fluid>
      <div>
        <div className="main">
          <Row>
            <Col>
              <div className="contentbox">
                <h1 className="title">Stammdaten Herde</h1>
                <Row className="mb-3">
                  <Col>
                    <Card className="shadow mb-3">
                      <Card.Header className="py-3">
                        <p className="m-0 fw-bold">Neue Herde</p>
                      </Card.Header>
                      <Card.Body>
                        <form className="needs-validation">
                          <Row>
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  // disabled
                                  type="text"
                                  name="bezeichnung"
                                  className="form-control"
                                  id="floatingInput"
                                  defaultValue={""}
                                  onChange={handleChange}
                                  required
                                />
                                <label htmlFor="floatingInput">
                                  Herden-Bezeichnung
                                </label>
                              </div>
                            </Col>

                            <Col sm={6}>
                              <div className="form-floating mb-3">
                              <input
                                    type="date"
                                    className="form-control"
                                    id="floatingInput"
                                    name="einstallung"
                                    defaultValue=""
                                    onChange={handleChange}
                                    required
                                />
                                <label>Einstallung</label>
                              </div>
                            </Col>
                          </Row>
                          <Row className="pb-2">
                            <Col sm={6}>
                                <div className="form-floating mb-3">
                                    <select
                                        className="form-control"
                                        defaultValue={""}
                                        onChange={handleBreedChange}
                                        name="rasseID"
                                        // required
                                    >
                                        {/* {inputs.bezeichnung ? (
                                        <option hidden value="">
                                            {inputs.bezeichnung}
                                        </option>
                                        ) : (
                                        <option hidden value="">
                                            -- Bitte ausw&auml;hlen --
                                        </option>
                                        )} */}


                                        <option hidden value="">
                                            -- Bitte ausw&auml;hlen --
                                        </option>
    

                                        {breeds.map((breed) => {
                                        return (
                                            <option
                                            key={breed.id}
                                            value={breed.id}
                                            >
                                            {breed.bezeichnung}
                                            {/* {console.log(inputs)} */}
                                            </option>
                                        );
                                        })}
                                    </select>
                                    <label htmlFor="floatingSelect">Rasse</label>
                                </div>
                            </Col>

                            <Col sm={6}>
                              <div className="form-floating mb-3">
                              <input
                                  type="date"
                                  className="form-control"
                                  id="floatingInput"
                                  name="ausstallung"
                                  defaultValue=""
                                  onChange={handleChange}
                                  required
                                />
                                <label>Ausstallung</label>
                              </div>
                            </Col>
                          </Row>
                          
                          <Row className="pb-2">
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  // disabled={activetrip.statusID === 6 }
                                  type="text"
                                  name="nutzart"
                                  className="form-control"
                                  id="floatingInput"
                                  // defaultValue={selectedBreedUsage || ""}
                                  value={selectedBreedUsage || ""}
                                  readOnly
                                />
                                <label htmlFor="floatingInput">Nutzart</label>
                              </div>
                              
                            </Col>

                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  // disabled={activetrip.statusID === 6 }
                                  type="number"
                                  name="anzahl"
                                  className="form-control"
                                  id="floatingInput"
                                  // defaultValue={inputs.anzahl || ""}
                                  value={""}
                                  onChange={handleChange}
                                  min="0"
                                  step="1"
                                  required
                                  // readOnly
                                />
                                <label htmlFor="floatingInput">Anzahl</label>
                              </div>
                            </Col>

                            {/* <Col sm={6}>
                                <div className="form-floating mb-3">
                                    <select
                                        className="form-control"
                                        defaultValue={""}
                                        onChange={handleChange}
                                        name="stallID"
                                        required
                                    > */}
                                        {/* {
                                        inputs.bezeichnung 
                                        ? (
                                            <option hidden value="">
                                                {inputs.bezeichnung}
                                            </option>
                                        ) : (
                                            <option hidden value="">
                                                -- Bitte ausw&auml;hlen --
                                            </option>
                                        )} */}
                                    

                                            {/* <option hidden value="">
                                                -- Bitte ausw&auml;hlen --
                                            </option>
                                   

                                        {stables.map((stable) => {
                                        return (
                                            <option
                                            key={stable.id}
                                            value={stable.id}
                                            >
                                            {stable.bezeichnung}
                                  
                                            </option>
                                        );
                                        })}
                                    </select>
                                    <label htmlFor="floatingSelect">Stall</label>
                                </div>
                            </Col> */}
                          </Row>

                          <Button
                            onClick={handleClickSave}
                            className="submitbutton"
                          >
                            &Auml;nderungen speichern
                          </Button>
                        </form>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default NewFlock;
