/////////////////////////////////////////////////////////////////////////////////////
//
// Projekt Tierwohl-KI
// COPYRIGHT (c)2023 by novutrack GmbH
//
// Photos.js
// Fotos Page der Anwendung
//
// Author:          Markus Seidl
// Erstellt am:     29.02.2024
// Letzte Änderung: 29.02.2024
// von:             Markus Seidl
//
///////////////////////////////////////////////////////////////////////////////////

import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Card,
  Table,
  Form,
  Row,
  Col,
  Pagination,
  Button,
} from "react-bootstrap";
import axios from "axios";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import moment from 'moment';
import { LightboxKey, Team } from "../env.js";

import { SlideshowLightbox, initLightboxJS } from "lightbox.js-react";
import "lightbox.js-react/";
import "../css/imageCarousel.scss";
import "lightbox.js-react/dist/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import defaultImage from "../images/logo_CW.png";
import { Store } from "react-notifications-component";
import '@fortawesome/fontawesome-free/css/all.min.css';


function StatisticsOverview(data) {

    let tableData = data.data;
  
//   const [tableData, setTableData] = useState([]);
//   console.log("tableData",tableData);



  

//   const handleDropdownChange = (e) => {
//     setSelectedFolkID(e.target.value);
//   };

  window.moment = moment;
  // moment.defineLocale("de");
  moment.locale();

  //musste die logik umdrehen das es klappt mit der filterung

  /**
   * Spalten und Überschriften
   */
  const columns = [
    {
      name: 'tag_iso',
      header: 'Datum',
      minWidth: 150,
      defaultFlex: 1,
      dateFormat: 'DD.MM.YYYY', // korrektes Format hier einstellen
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) => {
        return {
          dateFormat: 'YYYY-MM-DD', // oder welches Format Sie für den Filter bevorzugen
          placeholder: index === 1 ? '' : '' // oder entsprechenden Platzhaltertext
        };
      },
      render: ({ value }) => moment(value).format('DD.MM.YYYY'), // Formatierung hier
    },
    {
        name: "durchschnitt",
        header: "Herden-Bezeichnung",
        type: "string",
        minWidth: 150,
        defaultFlex: 1,
    },



     
      
  ];






  /**
   * Anlegen von übergeordneten Gruppen
   */
  const groups = [{ name: "other", header: "Sonstiges" }];

  const defaultSortInfo = { name: 'datum', dir: -1 }
  /**
   * Filterung
   */
  const filterValue = [

    {
      name: "tag_iso",
      operator: "afterOrOn",
      type: "date",
      value: "",
      dir: -1
      // sortDesc: "DESC"
    },
    {
        name: "durchschnitt",
        operator: "gte", // Nutzen Sie einen geeigneten Operator für den Typ der Spalte
        type: "number", // Stellen Sie sicher, dass der Typ korrekt ist
        value: "" // Passen Sie dies basierend auf dem tatsächlichen Wert an
    }

  ];

  const [selected, setSelected] = useState({});



  const i18n = Object.assign({}, ReactDataGrid.defaultProps.i18n, {
    // sortAsc: 'Execute ASC sort',
    // sortDesc: 'Execute DESC sort',
    // clear: 'Remove',
    // clearAll: 'Remove All'
    pageText:'Seite ',
    ofText:' von ',
    perPageText:'Ergebnisse pro Seite',
    showingText:'Zeigt '

  })
  
//   const selectedHerde = folk.find(herde => herde.id.toString() === selectedFolkID);

  const gridStyle = { minHeight: 550 };
  // console.log(tableData);

  return (
    // <Container fluid>
    //   <div>
    //     <div className="main">
    //       <Row>
    //         <Col>
    //           <div className="contentbox">
             
   
             
        <div>

                    {/* <select
                    style={{ width: "auto" }}
                    className="form-control"
                    value={selectedFolkID}
                    onChange={handleDropdownChange}
                    required
                  >
                    {folk.map((herde) => (
                      <option key={herde.id} value={herde.id}>
                        {herde.bezeichnung}
                        
                      </option>
                    ))}
                  </select> */}

{/* 
                    <Row> */}
                      <ReactDataGrid
                        idProperty="statisticsOverview"
                        defaultFilterValue={filterValue}
                        columns={columns}
                        groups={groups}
                        //onEditComplete={onEditComplete}
                        editable={false}
                        dataSource={tableData}
                        style={gridStyle}
                        defaultLimit={10}
                        selected={selected}
                        
                        pagination
                        i18n={i18n}
                        defaultSortInfo={defaultSortInfo}
  
                      />
                    {/* </Row> */}

                    </div>
      
    //           </div>
    //         </Col>
    //       </Row>
    //     </div>
    //   </div>
    // </Container>
  );
}

export default StatisticsOverview;
