/////////////////////////////////////////////////////////////////////////////////////
//
// Projekt Tierwohl-KI
// COPYRIGHT (c)2023 by novutrack GmbH
//
// Overview.js
// Übersicht page der Anwendung
//
// Author:          Markus Seidl
// Erstellt am:     29.02.2024
// Letzte Änderung: 29.02.2024
// von:             Markus Seidl
//
///////////////////////////////////////////////////////////////////////////////////

import React from "react";

import Table from "react-bootstrap/Table";
import {
  Container,
  Row,
  Col,
  Modal,
  Form,
  Card,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../css/main.scss";
import Chart from "chart.js";
import PieChartComponent from "../components/PieChartComponent";
import LineChartComponent from '../components/LineChartComponent';
import StatisticsOverview from "../components/StatisticsOverview";

const Overview = (info) => {
  let [currentUser, setCurrentUser] = useState(null);

  currentUser = info.info;
  // console.log(currentUser);

  // const currentUser = JSON.parse(localStorage.getItem("user"));

  const [operationData, setOperationData] = useState([]);

  const [selectedFolkId, setSelectedFolkId] = useState("");

  const [folk, setFolk] = useState([]);

  const [statisticsData, setStatisticsData] = useState([]);
  const [todayStatisticsData, setTodayStatisticsData] = useState([]);
  const [allDayStatisticsData, setAllDayStatisticsData] = useState([]);

  useEffect(() => {
    const fetchtOperationData = async () => {
      try {
        let operationID;
        if (currentUser != null) {
          operationID = currentUser.betriebID;

          const res = await axios.get("/api/operation/", {
            params: { operationID, bust: Date.now() },
          });
          // console.log(res.data);
          setOperationData(res.data);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchtOperationData();
  }, []);

  // const herdeID = 2;

  useEffect(() => {
    const fetchFolk = async () => {
      try {
        // console.log(currentUser);
        if (currentUser != null) {
          const operationID = currentUser.betriebID;
          const res = await axios.get("/api/folk", {
            params: { operationID },
          });
          // console.log(res.data);
          setFolk(res.data);

          if (res.data.length > 0) {
            setSelectedFolkId(res.data[0].id.toString());
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchFolk();
  }, []);

  const lineChartLabels = allDayStatisticsData.map(row => row.tag);
  const lineChartData = allDayStatisticsData.map(row => row.durchschnitt);

  const handleFolkChange = (e) => {
    setSelectedFolkId(e.target.value);
  };

  useEffect(() => {
    const fetchStatisticsData = async () => {
      if (selectedFolkId) {
        // Stelle sicher, dass eine herdeID ausgewählt wurde
        try {
          const res = await axios.get("/api/statistics/", {
            params: { herdeID: selectedFolkId, bust: Date.now() },
          });
          // console.log("res ", res.data);
          setStatisticsData(res.data);
        } catch (err) {
          console.log(err);
        }
      }
    };

    fetchStatisticsData();
  }, [selectedFolkId]);

  useEffect(() => {
    const fetchTodayStatisticsData = async () => {
      if (selectedFolkId) {
        // Stelle sicher, dass eine herdeID ausgewählt wurde
        try {
          const res = await axios.get("/api/statistics/today-statistics", {
            params: { herdeID: selectedFolkId, bust: Date.now() },
          });
          // console.log("today: ", res.data);
          setTodayStatisticsData(res.data);
        } catch (err) {
          console.log(err);
        }
      }
    };

    fetchTodayStatisticsData();
  }, [selectedFolkId]);

  useEffect(() => {
    const fetchAllDayStatisticsData = async () => {
      if (selectedFolkId) {
        // Stelle sicher, dass eine herdeID ausgewählt wurde
        try {
          const res = await axios.get("/api/statistics//allday-statistics", {
            params: { herdeID: selectedFolkId, bust: Date.now() },
          });
          // console.log("today: ", res.data);
          setAllDayStatisticsData(res.data);
        } catch (err) {
          console.log(err);
        }
      }
    };

    fetchAllDayStatisticsData();
  }, [selectedFolkId]);

  const selectedHerde = folk.find(
    (herde) => herde.id.toString() === selectedFolkId
  );

  const chartData =
    statisticsData.length > 0
      ? [statisticsData[0].herdeistalter, statisticsData[0].altersdifferenz]
      : [0, 0];

  const statusChartData = selectedHerde
    ? [selectedHerde.positiveDaten || 0, selectedHerde.negativDaten || 0]
    : [0, 0];

  // if (typeof selectedHerde !== 'undefined') {
  //   const statusChartData = [selectedHerde.positiveDaten || 0, selectedHerde.negativDaten || 0];

  //   // Und dann nutzen Sie statusChartData irgendwo in Ihrem Render-Code
  // }

  return (
    // <Container fluid>
    <Container fluid>
      <div>
        <div className="main">
          <Row>
            <Col>
              <div className="contentbox">
                <h1 className="title">Dashbord</h1>

                <Row className="mb-3">
                  <Col>
                    <Card className="shadow mb-3">
                      <Card.Header className="py-3 d-flex justify-content-between align-items-center">
                        <p className="m-0 fw-bold">Herde Diagramm</p>

                        <select
                          style={{ width: "auto" }}
                          className="form-control"
                          value={selectedFolkId}
                          onChange={handleFolkChange}
                          required
                        >
                          {folk.map((f) => (
                            <option key={f.id} value={f.id}>
                              {f.bezeichnung}
                            </option>
                          ))}
                        </select>
                      </Card.Header>

                      <Card.Body>
                        <Row>
                          <Col style={{ borderRight: "1px solid #dee2e6" }}>
                            {selectedHerde ? (
                              <div>
                                <b>Einstalldatum: </b>{" "}
                                {selectedHerde.einstallDatum} <br></br>
                                <b>Ausstalldatum: </b>{" "}
                                {selectedHerde.ausstallDatum} <br></br>
                                <b>Rasse: </b> {selectedHerde.rassenBezeichnung}{" "}
                                <br></br>
                                <b>Anzahl: </b> {selectedHerde.anzahl} <br></br>
                                <b>Stall: </b> {selectedHerde.stallBezeichnung}{" "}
                                <br></br>
                              </div>
                            ) : (
                              <div>Herde auswählen</div>
                            )}
                          </Col>
                          <Col >
                            {selectedHerde ? (
                              <div>
                                <b>Befund: </b>
                                <br></br>
                                <b>
                                  {selectedHerde.positiveDaten} positiv von{" "}
                                  {selectedHerde.negativDaten}
                                </b>{" "}
                                <br></br>
                                <hr></hr>
                                <br></br>
                                {todayStatisticsData &&
                                todayStatisticsData.length > 0 ? (
                                  <b>
                                    Heute wurden{" "}
                                    <span style={{ color: "red" }}>
                                      {todayStatisticsData[0].anzahl}
                                    </span> {" "}
                                    Datensätze aufgenommen und das
                                    Durchschnittsgewicht beträgt{" "}
                                    <span style={{ color: "red" }}>
                                      {todayStatisticsData[0].durchschnitt
                                        ?.toFixed(2)
                                        .replace(".",",") || "0,00"}g
                                    </span>
                                    .
                                  </b>
                                ) : (
                                  <div>Keine Daten für heute verfügbar.</div>
                                )}
                              </div>
                            ) : (
                              <div>Herde auswählen</div>
                            )}
                          </Col>
                          <Col >
                            <PieChartComponent
                              data={statusChartData}
                              title="Status der Daten"
                            />
                          </Col>
                          <Col >
                            <PieChartComponent
                              data={chartData}
                              title="Altersverteilung"
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <Card className="shadow mb-3">
                      <Card.Header className="py-3 d-flex justify-content-between align-items-center">
                        <p className="m-0 fw-bold">Herde Diagramm</p>

                        <select
                          style={{ width: "auto" }}
                          className="form-control"
                          value={selectedFolkId}
                          onChange={handleFolkChange}
                          required
                        >
                          {folk.map((f) => (
                            <option key={f.id} value={f.id}>
                              {f.bezeichnung}
                            </option>
                          ))}
                        </select>
                      </Card.Header>

                      <Card.Body>
                        <Row>
                        <Col 
                        // sm={6} 
                        // xs={12} 
                        // md={6}
                        >

                          <StatisticsOverview data={allDayStatisticsData}></StatisticsOverview>
  
                          </Col>
                          <Col 
                          // sm={6} 
                          // xs={12} 
                          // md={6}
                          >


                          <LineChartComponent data={lineChartData} labels={lineChartLabels} title="Durchschnittsgewicht im Zeitverlauf" />
                         
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default Overview;
