import React, { useEffect, useRef } from 'react';
import { Chart, PieController, ArcElement, Tooltip, Legend } from 'chart.js';
import { Title } from 'chart.js';
Chart.register(Title);

Chart.register(PieController, ArcElement, Tooltip, Legend);

const PieChartComponent = ({ data, title }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  // console.log(data);
  // console.log(title);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');

      // Bestimmen der Labels basierend auf dem Titel
      const labels = title === "Status der Daten" 
      ? ['Positiv', 'Negativ']
      : ['Eingestallt in Tagen', 'Tage bis zur Ausstallung'];

      // Farben basierend auf dem Titel festlegen
      const backgroundColor = title === "Status der Daten" 
        ? ['#d7690e', 'rgba(201, 203, 207, 0.2)'] // Nur "Positiv" erhält die spezielle Farbe
        : ['rgba(186, 10, 10, 0.7)', 'rgba(201, 203, 207, 0.2)'];

        const borderColor = title === "Status der Daten" 
        ? ['#bf5e0a', 'rgba(201, 203, 207, 1)'] // Nur "Positiv" erhält die dunklere Randfarbe
        : ['rgba(186, 10, 10, 1)', 'rgba(201, 203, 207, 1)'];


      // Zerstören des alten Chart-Objekts, falls vorhanden
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      // Erstellen eines neuen Chart-Objekts
      chartInstanceRef.current = new Chart(ctx, 
        {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [{
            data: data,
            backgroundColor: backgroundColor,
              borderColor: borderColor,
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          animation: {
            duration: 0, // Keine Dauer für die Animation, führt dazu, dass sie sofort erscheint
          },

          plugins: {
            title: {
              display: true,
              text: title // Der Titel wird hier definiert
            },
            legend: {
              display: true, // Optional: Anzeigen der Legende ein-/ausschalten
              position: 'top', // Position der Legende
            },
        },
      },
    });
    }

    // Komponentenaufräumfunktion
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [data, title]); // Neu erstellen, wenn sich 'data' ändert }, [data]);

  return <canvas ref={chartRef} />;
};

export default PieChartComponent;
